import React from "react"

import Typography from "@material-ui/core/Typography"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
  return (
    <Layout>
      <SEO title="杂志 Contact" />
      <Typography variant="h4" gutterBottom>
        杂志 Contact Us
      </Typography>
      <Typography paragraph>
        <strong>East West Culture &amp; Art Center</strong>
        <br /> 6158 Innovation Way <br />
        Carlsbad, CA 92009 <br />
        P: (858) 356-6239, (858) 254-9283 <br />
        Email:
        <a href="mailto:info@eastwestcultureandart.com">
          info@eastwestcultureandart.com
        </a>
      </Typography>
    </Layout>
  )
}

export default Contact
